// Ionic Variables and Theming. For more information, please see
// https://beta.ionicframework.com/docs/theming/

// The app direction is used to include
// rtl styles in your app. For more information, please see
// https://beta.ionicframework.com/docs/layout/rtl
// $app-direction: ltr;

// Ionic Colors
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic provides eight layered colors
// that can be changed to theme an app. Additional colors can be
// added as well (see below). For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced

// To easily create custom color palettes for your app’s UI,
// check out our color generator:
// https://beta.ionicframework.com/docs/theming/color-generator

:root {
  --ion-color-primary: #ffce00;
  --ion-color-primary-rgb: 255, 206, 0;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0b500;
  --ion-color-primary-tint: #ffd31a;

  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-grey: #dddddd;
  --ion-color-grey-rgb: 221, 221, 221;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0, 0, 0;
  --ion-color-grey-shade: #c2c2c2;
  --ion-color-grey-tint: #e0e0e0;

  --ion-color-darkgrey: #666666;
  --ion-color-darkgrey-rgb: 102, 102, 102;
  --ion-color-darkgrey-contrast: #ffffff;
  --ion-color-darkgrey-contrast-rgb: 255, 255, 255;
  --ion-color-darkgrey-shade: #5a5a5a;
  --ion-color-darkgrey-tint: #757575;

  --ion-color-yellow: #ffce00;
  --ion-color-yellow-rgb: 255, 206, 0;
  --ion-color-yellow-contrast: #ffffff;
  --ion-color-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-yellow-shade: #e0b500;
  --ion-color-yellow-tint: #ffd31a;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
}
// Additional Ionic Colors
// --------------------------------------------------
// In order to add colors to be used with Ionic components,
// the color should be added as a class with the convention `.ion-color-{COLOR}`
// where `{COLOR}` is the color to be used on the Ionic component
// and each variant is defined for the color. For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced

.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-favorite {
  --ion-color-base: #69bb7b;
  --ion-color-base-rgb: 105, 187, 123;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #5ca56c;
  --ion-color-tint: #78c288;
}

.ion-color-twitter {
  --ion-color-base: #1da1f4;
  --ion-color-base-rgb: 29, 161, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1a8ed7;
  --ion-color-tint: #34aaf5;
}

.ion-color-google {
  --ion-color-base: #dc4a38;
  --ion-color-base-rgb: 220, 74, 56;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #c24131;
  --ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
  --ion-color-base: #23b6ea;
  --ion-color-base-rgb: 35, 182, 234;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1fa0ce;
  --ion-color-tint: #39bdec;
}

.ion-color-facebook {
  --ion-color-base: #3b5998;
  --ion-color-base-rgb: 59, 89, 152;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #344e86;
  --ion-color-tint: #4f6aa2;
}

// Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables

:root {
  // TODO
  // $headings-font-weight: 300;
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
}

// App iOS Variables
// --------------------------------------------------
// iOS only CSS variables can go here

//.ios {
//}

// App Material Design Variables
// --------------------------------------------------
// Material Design only CSS variables can go here

.md {
  // Use the primary color as the background for the toolbar
  --ion-toolbar-background: var(--ion-color-primary);

  // Change the color of the toolbar components
  --ion-toolbar-color: var(--ion-color-light);

  // Change the color of the activated toolbar components
  --ion-toolbar-color-activated: #fff;

  // Change the color of the unchecked segment buttons
  --ion-toolbar-color-unchecked: rgba(255, 255, 255, 0.6);

  // Change the color of the checked segment button
  --ion-toolbar-color-checked: #fff;
}

// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.

// @import "ionic.theme.default";

// Fonts
// --------------------------------------------------
// Roboto font is used by default for Material Design. Noto sans
// is used by default for Windows.

// @import "roboto";

@media (prefers-color-scheme: dark) {
  :root {
    --ion-border-color: #2a2a2a;
    --ion-background-color: var(--ion-color-light);
    --ion-background-color-rgb: var(--ion-color-light-rgb);
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #232323;
    --ion-color-step-100: #2e2e2e;
    --ion-color-step-150: #3a3a3a;
    --ion-color-step-200: #454545;
    --ion-color-step-250: #515151;
    --ion-color-step-300: #5d5d5d;
    --ion-color-step-350: #686868;
    --ion-color-step-400: #747474;
    --ion-color-step-450: #7f7f7f;
    --ion-color-step-500: #8b8b8b;
    --ion-color-step-550: #979797;
    --ion-color-step-600: #a2a2a2;
    --ion-color-step-650: #aeaeae;
    --ion-color-step-700: #b9b9b9;
    --ion-color-step-750: #c5c5c5;
    --ion-color-step-800: #d1d1d1;
    --ion-color-step-850: #dcdcdc;
    --ion-color-step-900: #e8e8e8;
    --ion-color-step-950: #f3f3f3;

    --ion-color-primary: #ffce00;
    --ion-color-primary-rgb: 255, 206, 0;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #e0b500;
    --ion-color-primary-tint: #ffd31a;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 244, 244;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 34, 34;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-color-grey: #666666;
    --ion-color-grey-rgb: 102, 102, 102;
    --ion-color-grey-contrast: #ffffff;
    --ion-color-grey-contrast-rgb: 255, 255, 255;
    --ion-color-grey-shade: #5a5a5a;
    --ion-color-grey-tint: #757575;

    --ion-color-darkgrey: #dddddd;
    --ion-color-darkgrey-rgb: 221, 221, 221;
    --ion-color-darkgrey-contrast: #000000;
    --ion-color-darkgrey-contrast-rgb: 0, 0, 0;
    --ion-color-darkgrey-shade: #c2c2c2;
    --ion-color-darkgrey-tint: #e0e0e0;

    --ion-color-yellow: #ffce00;
    --ion-color-yellow-rgb: 255, 206, 0;
    --ion-color-yellow-contrast: #000000;
    --ion-color-yellow-contrast-rgb: 0, 0, 0;
    --ion-color-yellow-shade: #e0b500;
    --ion-color-yellow-tint: #ffd31a;

    --ion-color-white: #222428;
    --ion-color-white-rgb: 34, 36, 40;
    --ion-color-white-contrast: #ffffff;
    --ion-color-white-contrast-rgb: 255, 255, 255;
    --ion-color-white-shade: #1e2023;
    --ion-color-white-tint: #383a3e;
  }
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-darkgrey {
  --ion-color-base: var(--ion-color-darkgrey);
  --ion-color-base-rgb: var(--ion-color-darkgrey-rgb);
  --ion-color-contrast: var(--ion-color-darkgrey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkgrey-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkgrey-shade);
  --ion-color-tint: var(--ion-color-darkgrey-tint);
}
