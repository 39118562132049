// App Global CSS
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply globally. These styles are for
// the entire app and not just one component. Additionally, this file can be
// also used as an entry point to import other Sass files to be included in the
// output CSS.

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "swiper/swiper.scss";
// @import "~swiper";

$mobile_view: 480px;
$tablet_view: 768px;
$desktop_view: 1024px;

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.desktop {
  @media screen and (max-width: $mobile_view) {
    display: none !important;
  }
}

.tablet {
  @media screen and (min-width: $tablet_view) {
    display: none !important;
  }
}

.mobile {
  @media screen and (min-width: $mobile_view) {
    display: none !important;
  }

  // @media screen and (min-width: $tablet_view) {
  //     display: none;
  // }
}

.animate_fadeInDown {
  animation-fill-mode: forwards;
  animation-duration: 300ms;
  animation-name: fadeInDown;
}

.animate_fadeInUp {
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  animation-name: fadeInUp;
}

.fadeIn {
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  animation-name: fadeIn;
}

.fadeOut {
  //animation-fill-mode: forwards;
  animation-duration: 500ms;
  animation-name: fadeOut;

  opacity: 0;
}

.max-width-600 {
  max-width: 600px !important;
  margin: auto;
}

.rotate {
  transform: rotate(180deg);
}

ion-popover.borderRad10::part(content) {
  border-radius: 10px;
  width: 298px;
  height: 241px;
}

ion-refresher.yellow {
  ion-spinner {
    color: var(--ion-color-yellow);
  }

  ion-icon {
    color: var(--ion-color-yellow) !important;
  }
}

.TOS-popover {
  &::part(content) {
    min-width: 75%;
    min-height: 75%;
  }
}

.toast-popup {
  --button-color:var(--ion-color-step-100, #e6e6e6);
}

.perma-dark {
  color: #464646 !important;
}

.linked-text {
  a {
    @media (prefers-color-scheme: light) {
      color: blue;
    }
  }
}

.delete-acc-popover {
  &::part(content) {
    padding: 50px;
    --height: 200px;
  }
}

.editContentSelectItem {
  button {
    color: var(--ion-color-dark) !important;
  }
}

.monetize-popover {
  --width: 90%;
  @media (min-width: 1200px) {
    --height: 80%;
    --width: 50%;
  
  }
  --ion-background-color: var(--ion-color-light-tint);
}

.forgotPasswordPopover {
  &::part(content) {
    padding: 10px;
  }
  --width: 342px;
}

.resetPasswordPopover {
  &::part(content) {
    padding: 10px;
  }
  --width: 342px;
}